<div class="page">
  <div class="eduListContainer col-6">
    <ul class="listContainer">
      <li>case western reserve university, may2016</li>
      <ul>
        <li>
          b.s.e biomedical engineering. focus on computing and analysis. minor
          in systems and control engineering
        </li>
      </ul>
      <div class="skills">
        <li>skills</li>
        <ul>
          <li>angular, vue, react, wordpress, hugo, gatsby</li>
          <li>tailwind, scss, graphql, d3, echarts, recharts</li>
          <li>jasmine, jest</li>
          <li>
            javascript, typescript, css, html, git, npm, sql, matlab, java,
            python, php
          </li>
        </ul>
      </div>
    </ul>
  </div>
  <div class="expListContainer col-6">
    <ul class="listContainer">
      <li>freelance, remote, oct2020</li>
      <ul>
        <li>
          about pages for <a href="https://debtcollectionlab.org/about-us">the debt collection lab</a>
          and <a href="https://northtexasevictions.org/about">the north texas eviction project</a>
        </li>
        <li>
          data visualizations for
          <a
            href="https://edopportunity.org/discoveries/racial-inequality-predicts-academic-inequality/"
            >the educational opportunity project</a
          >
        </li>
        <li>
          data integration ucla law's
          <a href="https://www.uclacovidbehindbars.org">covid behind bars</a>
          project
        </li>
        <li>
          ui controls and figures for
          <a
            href="https://www.diversitydatakids.org/maps/#/explorer/0/15/10,15/ap,hi,w,b,ai/xc/m/1.0.14/38.212/-85.682/5.62/"
            >the diversity data kids project</a
          >
        </li>
        <li>
          wordpress management and development for
          <a href="https://www.leadershipacademy.org"
            >the nyc leadership academy</a
          >
          and
          <a href="https://www.southbronxcommunity.org"
            >the south bronx community charter high school</a
          >.
        </li>
        <li>
          shortcode development for
          <a href="https://evictionlab.org">the eviction lab</a>
        </li>
      </ul>
      <li>pepperlane, cambridge ma, feb2020-apr2020</li>
      <ul>
        <li>focused on responsive web pages for both desktop and mobile</li>
        <li>
          sole front end developer, taking full responsiblity for all features
        </li>
        <li>
          collaborated with design team to ensure consistency in aesthetic
          across the site
        </li>
        <li>worked with vue, node, rails, aws</li>
      </ul>
      <li>accenture, boston ma, jan2017-feb2020</li>
      <ul>
        <li>
          implemented innovative program management strategy and software for
          global pharmaceutical merger
        </li>
        <li>
          developed front end software for the federal reserve bank of new york
        </li>
        <li>
          primary developer of front end features on high-vis internal personnel
          forecasting tool
        </li>
        <li>
          worked with excel, project, vba, angular, vue, jasmine, jest, sql, aws
        </li>
      </ul>
    </ul>
  </div>
  <div id="bg" class="bg"></div>
</div>
