<!--<div style="width: 100vw; position: fixed;">
    <div id="nav">
        <div style="font-size: 2rem; text-align: center; vertical-align: middle; color: #00dd4a;">
            alex
        </div>
        <div style="font-size: 0.8rem; font-family: Arial, Helvetica, sans-serif;">
            <div style="text-align: center;">
                <a href="#bio" style="color:black; text-decoration: none; padding: 15px; cursor: pointer;">bio</a>
                <a href="#resume" style="color:black; text-decoration: none; padding: 15px; cursor: pointer;">resume</a>
                <a href="#projects" style="color:black; text-decoration: none; padding: 15px; cursor: pointer;">projects</a>
                <a href="#contact" style="color:black; text-decoration: none; padding: 15px; cursor: pointer;">contact</a>
            </div>
        </div>
        <div style="font-size: 2rem; margin: auto; text-align: center; vertical-align: middle; color: #00dd4a;">
            deutch
        </div>
    </div>
</div>-->

<header id="nav">
    <div id="navContainer">
        <div id="navName">
            alex
        </div>
        <div id="navLinks">
            <a href="#bio" id="hashlink">bio</a>
            <a href="#resume" id="hashlink">resume</a>
            <a href="#projects" id="hashlink">projects</a>
            <a href="#contact" id="hashlink">contact</a>
        </div>
        <div id="navName">
            deutch
        </div>
    </div>
</header>