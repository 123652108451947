import { Component, ElementRef, ViewChild, OnInit } from '@angular/core';
import { NavComponent } from '../../components/nav/nav.component'

@Component({
    selector: 'resume-page',
    templateUrl: './resume.component.html',
    styleUrls: ['./resume.component.scss']
  })

  export class ResumeComponent {

  };