<div id="biggestContainer" class="full-screen-div" style="display: flex; flex-direction: column; height: 100%; width: 100vw; max-width:100%;">
    <div id="bigContainer" style="flex-grow: 1; justify-content: center;">
        <img [hidden]="true" #img rel:animated_src="">
    </div>
    <div style="margin: auto; height: 40px">
        <div *ngIf="!playing" (click)="play()" style="text-align:center; width: 5rem; border-style: solid; padding: 5px; cursor: pointer">
            play
        </div>
        <div *ngIf="playing" (click)="pause()" style="text-align:center; width: 5rem; border-style: solid; padding: 5px; cursor: pointer">
            pause
        </div>
    </div>
    <!--<div style="text-align: center;">
        <div *ngIf="!playing && loaded" (click)="play()" style="cursor: pointer">PLAY</div>
        <div *ngIf="playing && loaded" (click)="pause()" style="cursor: pointer">PAUSE</div>
        <div *ngIf="!playing && !loaded" style="color: white; cursor: default">PLAY</div>
        <div *ngIf="playing && !loaded" style="color: white">PAUSE</div>
        <input placeholder="gif url" [(ngModel)]="gifUrl" (ngModelChange)="showLoad = true">
        <div *ngIf="showLoad" (click)="load()" style="cursor: pointer">LOAD</div>
        <div *ngIf="!showLoad" (click)="load()" style="color: white; cursor: default">LOAD</div>
    </div>-->
</div>

