<body style="width: 100vw; height:100%; max-height: 100vh; display:flex;">
    
    <div *ngIf="gifList.length == 0" style="justify-content: center; margin: auto; overflow: hidden">
        <input placeholder="what do you want to hear" style="text-align: center; border-style: solid; padding: 5px;" [(ngModel)]="searchTerm" (keyup.enter)="search(searchTerm)">
    </div>

    <table *ngIf="gifList.length > 0" style="width: 100vw; height: 100%">
        <tr *ngFor="let row of gifList">
            <td *ngFor="let col of row" style="vertical-align: middle; text-align: center;">
                <div>
                    <span style="font-size: 70%; vertical-align: top; writing-mode: vertical-rl; text-orientation: sideways">click one</span>
                    <img (click)="emitGif(col)" style="object-fit: contain; height: 20%; width: 20%" [src]="col">
                </div>
            </td>
        </tr>
    </table> 

</body>
